// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';


var $ = require('jquery');
var Link = require('../_modules/link/link');
require('bootstrap');

$(function() {
//  new Link(); // Activate Link modules logic
//  console.log('Welcome to Yeogurt!');
});
